import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as Sentry from "@sentry/react";

import rootReducer from "./Reducers/root";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["home", "notification", "downloadingProcessModel"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: false,
});

export default () => {
  let store = createStore(persistedReducer, sentryReduxEnhancer);
  let persistor = persistStore(store);
  return { store, persistor };
};
